import React from "react";
import { Link } from "react-router-dom";
import banner from "../images/banner.jpg";
import background1 from "../images/background1.jpg";
import { slide as Menu } from "react-burger-menu";

function About() {
  return (
    <div>
      <Menu disableAutoFocus right width={"80%"}>
        <Link to="/" className="link">
          <div className="navHomeArt">HOME</div>
        </Link>
        <Link to="/art" className="link">
          <div className="navHomeArt">ART</div>
        </Link>
      </Menu>

      <nav className="navContainer">
        <Link to="/" className="simonaLink">
          <div className="navSimona">SIMONA CONTI</div>
        </Link>
        <div className="navMenu">
          <Link to="/" className="link">
            <div className="navHomeArt">HOME</div>
          </Link>
          <Link to="/art" className="link">
            <div className="navHomeArt">ART</div>
          </Link>
        </div>
      </nav>
      <div className="bannerText">
        Simona Conti is an artist based in New York
      </div>
      <img src={banner} alt="banner" className="banner" />
      <div className="bioTag">About</div>
      <div className="bioContainer">
        <div className="bioWrapper">
          <div className="aboutTitle">
            Born in Rimini, Italy, Simona Conti is an Italian artist who has
            lived and worked in Manhattan since 2009
          </div>{" "}
          <div className="aboutMid">
            The bridges she burned lit her way to become a self-taught abstract
            painter
          </div>{" "}
          <div className="aboutMid">
            Her process is slow, deliberative, and unconventional
          </div>{" "}
          <div className="aboutMid">
            She believes her art should speak for itself
          </div>{" "}
          <div className="aboutMid">
            Inspired by spirituality, her Italian culture and the fantastic
            people of New York
          </div>{" "}
          <div className="aboutEnding2">
            She walks her own road in the art world
          </div>
        </div>
        <img src={background1} alt="background1" className="background1" />
      </div>
      <div className="bannerContainer">
        <img src={banner} alt="banner" className="bannerBottom" />
      </div>
    </div>
  );
}

export default About;
