import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import FrontPage from "./components/FrontPage";
import About from "./components/About";
import Art from "./components/Art";

function App({ location }) {
  return (
    <div>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames={"fade"}
        >
          <Switch location={location}>
            <Route exact path="/">
              <div className="frontPageContainer">
                <FrontPage />
              </div>
            </Route>
            <Route path="/about">
              <div className="aboutContainer">
                <About />
              </div>
            </Route>
            <Route path="/art">
              <div className="artContainer">
                <Art />
              </div>
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default withRouter(App);
