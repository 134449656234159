import React from "react";
import { Link } from "react-router-dom";
import facebook from "../images/fb.png";
import instagram from "../images/instagram.png";
import { slide as Menu } from "react-burger-menu";

function FrontPage() {
  return (
    <div>
      <Menu disableAutoFocus right width={"80%"}>
        <Link to="/about" className="link">
          <div className="navHomeArt">ABOUT</div>
        </Link>
        <Link to="/art" className="link">
          <div className="navHomeArt">ART</div>
        </Link>
      </Menu>
      <nav className="navContainer">
        <Link to="/" className="simonaLink" style={{ visibility: "hidden" }}>
          <div className="navSimona">SIMONA CONTI</div>
        </Link>
        <div className="navMenu">
          <Link to="/about" className="link">
            <div className="navHomeArt">ABOUT</div>
          </Link>
          <Link to="/art" className="link">
            <div className="navHomeArt">ART</div>
          </Link>
        </div>
      </nav>
      <div className="menuCenter">
        <div className="menuContainer">
          <div className="introName">SIMONA CONTI</div>
        </div>
      </div>
      <div className="socialCenter">
        <div className="socialContainer">
          <div className="email">simonaconti16@gmail.com</div>
          <a
            href="https://www.facebook.com/profile.php?id=100011153738678"
            target="_blank"
          >
            <img src={facebook} alt="facebook" className="fbIcon" />
          </a>
          <a
            href="https://www.instagram.com/simonaconti16/?hl=en"
            target="_blank"
          >
            <img src={instagram} alr="instagram" className="instaIcon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FrontPage;
