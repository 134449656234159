import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import { slide as Menu } from "react-burger-menu";
import banner2 from "../images/banner2.jpg";

const images = [];

for (let i = 1; i < 23; i++) {
  images.push({
    pic: require(`../images/pic${i}.jpg`),
    detail: require(`../images/detail${i}.jpg`),
  });
}
images[0].info =
  '"My World Is Gonna Pull Through!" / Mixed media on canvas / 42" × 62" | 108 × 160 cm';
images[1].info =
  '"Ready For Whatever!" / Mixed media on stretched canvas / 16" × 20" | 40 × 50 cm';
images[2].info =
  '"Struggle For Pleasure" / Mixed media on canvas / 41" × 36" | 92 × 105 cm';
images[3].info =
  'Today is the day, Diotima" / Mixed media on canvas / 35" × 44" | 90 × 113 cm';
images[4].info =
  '"You Set Me Free" / Mixed media on paper (wood support) / 18" × 24" | 46 × 61 cm';
images[5].info =
  '"Not Waiting But Living" / Mixed media on paper (wood support) / 18" × 24" | 46 × 61 cm';
images[6].info =
  '"My Ego Is Melting" / Mixed media on paper (polycarbonate support) / 18" × 24" | 46 × 61 cm';
images[7].info =
  '"I Tell You My Secret" / Mixed media on paper (wood support) / 18" × 24" | 45 × 61 cm';
images[8].info =
  '"Clodia Remembers Catullus" / Mixed media on canvas / 49" × 69" | 92 × 125 cm';
images[9].info =
  '"The Substance My Dream Is Made Of" / Mixed media on canvas / 36" × 49" | 79 × 108 cm';
images[10].info =
  '"The Wedding Rings" /  Mixed media canvas / 31" × 43" | 79 × 108 cm';
images[11].info =
  '"A Bite of You" / Mixed media on paper (wood support) / 18" × 24" | 46 × 61 cm';
images[12].info =
  '"I Am Late, Aren’t I?" /  Mixed media on stretched canvas / 49" × 69" | 76 × 103 cm';
images[13].info =
  '"Am I Nuts?" / Mixed media on paper (wood support) / 18" × 24" | 46 × 61 cm';
images[14].info =
  '"Terra Incognita" /  Mixed media on paper (wood support) / 18" × 24" | 45 × 61 cm';
images[15].info =
  '"I\'m Not Gentle!" / Mixed media on paper (wood support) / 28" × 24" | 46 × 61 cm';
images[16].info =
  '"You Are Me" / Mixed media on paper (wood support) / 18" × 34" | 46 × 61 cm';
images[17].info =
  '"The Dream Is True" / Mixed media on paper (wood support) / 11" × 17" | 30 × 45 cm';
images[18].info =
  '"A Little Bite Of You" / Mixed media on stretched canvas / 10" × 10" | 25 × 25 cm';
images[19].info =
  '"Inspired By A Man From Queens" / Mixed media on stretched canvas / 30" × 40" | 76 × 102 cm';
images[20].info =
  '"W 36 street" / Mixed media on stretched canvas / 36" × 48" | 91 × 122 cm';
images[21].info =
  '"Learning Sigils" / Mixed media on stretched canvas / 16" × 20" | 34 × 50cm';

function Gallery() {
  const [isOpen, changeValue] = useState(false);

  const open = () => {
    changeValue(!isOpen);
  };
  const close = () => {
    changeValue(false);
  };

  return (
    <div>
      <Menu disableAutoFocus right width={"80%"}>
        <Link to="/" className="link">
          <div className="navHomeArt">HOME</div>
        </Link>
        <Link to="/about" className="link">
          <div className="navHomeArt">ABOUT</div>
        </Link>
      </Menu>
      <nav className="navContainer">
        <Link to="/" className="simonaLink">
          <div className="navSimona">SIMONA CONTI</div>
        </Link>
        <div className="navMenu">
          <Link to="/" className="link">
            <div className="navHomeArt">HOME</div>
          </Link>
          <Link to="/about" className="link">
            <div className="navHomeArt">ABOUT</div>
          </Link>
        </div>
      </nav>
      <div className="bannerText" style={{ fontStyle: "italic" }}>
        "Making art is a way of sharing my soul with the world"
      </div>
      <img src={banner2} alt="banner2" className="artBanner" />

      {images.map((item) => (
        <div className="modalContainer" onClick={() => open()}>
          <div className="bigModalWrapper">
            <ModalImage
              hideZoom
              hideDownload
              small={item.pic}
              large={item.pic}
              alt={item.info}
              className={isOpen ? "OPEN" : ""}
            />
          </div>
          <div className="smallModalWrapper">
            <ModalImage
              hideDownload
              small={item.detail}
              large={item.detail}
              className={isOpen ? "OPEN smallModal" : "smallModal"}
            />
          </div>
        </div>
      ))}
      <div className="bannerContainer">
        <img src={banner2} alt="banner2" className="bannerBottom" />
      </div>
    </div>
  );
}

export default Gallery;
